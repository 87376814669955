import { useTranslation } from 'react-i18n-lite'
import { useLoginContext } from './LoginContext'
import { Button, LinkButton, Unifree } from '@yes.technology/react-toolkit'

type Props = {
  goToIdentification: () => void
}

function Authentication({ goToIdentification }: Props) {
  const { t } = useTranslation()
  const { handleLogin, password, setPassword, captcha } = useLoginContext()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleLogin()
  }

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
      onSubmit={handleSubmit}
    >
      <Unifree
        label={t('login.authentication.password')}
        placeholder={t('login.authentication.password')}
        name='password'
        type='password'
        value={password}
        actionButton={
          window.displayRecoverPassword ? (
            <LinkButton
              heightClass='small'
              variant='secondary'
              href='recover-password'
            >
              {t('login.authentication.recover-password')}
            </LinkButton>
          ) : null
        }
        onChange={setPassword}
        autoFocus
      />
      <Button disabled={!captcha || !password} type='submit'>
        {t('login.authentication.continue')}
      </Button>
      <Button onClick={goToIdentification} variant='secondary'>
        {t('login.authentication.go-back')}
      </Button>
    </form>
  )
}

export default Authentication

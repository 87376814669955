import { Routes, Route } from 'react-router-dom'

import { PrivateRoutes } from 'infrastructure/shared/components'
import ChangePassword from 'authentication/ChangePassword'
import Credentials from 'authentication/Credentials'
import ActiveSession from '../ActiveSession'
import WebAuthn from '../Webauthn'
import Login from '../Login'
import Registration from '../Webauthn/Registration'
import Authentication from '../Webauthn/Authentication'
import Delete from '../Webauthn/Delete'
import ChangePasswordSuccess from '../ChangePassword/Success'
import RecoverPassword from 'authentication/RecoverPassword'

const AuthenticationRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path='recover-password' element={<RecoverPassword />} />
      <Route path='login' element={<Login />} />
      <Route path='webauthn/authentication' element={<Authentication />} />
      <Route path='webauthn/delete' element={<Delete />} />
      <Route path='webauthn' element={<WebAuthn />} />

      <Route element={<PrivateRoutes />}>
        <Route path='change-password' element={<ChangePassword />} />
        <Route
          path='change-password-success'
          element={<ChangePasswordSuccess />}
        />
        <Route path='credentials' element={<Credentials />} />
        <Route path='active-session' element={<ActiveSession />} />
        <Route path='webauthn/registration' element={<Registration />} />
        <Route path='/' element={<Login />} />
      </Route>
    </Routes>
  )
}

export default AuthenticationRoutes

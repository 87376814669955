import { isEmbedded, useParentPostMessage } from '@yes.technology/react-toolkit'
import { useEffect } from 'react'

const RecoverPassword = () => {
  const { postMessage } = useParentPostMessage()

  useEffect(() => {
    const urlPath = `${window.workflowExecutorSitemodel}?workflowmodelUuid=${window.recoverPasswordProcessmodelUuid}`

    if (isEmbedded()) {
      postMessage({ type: 'redirect', url: `/${urlPath}` })
    } else {
      window.location.href = `${window.fallbackSiteRenderer}${urlPath}`
    }
  }, [postMessage])

  return null
}

export default RecoverPassword
